import React, { useEffect, useState } from "react";
import {
    MetaAdsAccount,
    MetaAdsCampaign,
    useCreateMetaAdsTemplateMutation,
    useGetAdsAccountsQuery,
    useUpdateMetaAdsTemplateMutation
} from "../../../../generated/graphql";
import {
    Box,
    Button,
    Card,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    TextField,
    Typography
} from "@material-ui/core";
import {ToggleButton} from "@material-ui/lab";
import {styled} from "@material-ui/styles";
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import CloseIcon from "@material-ui/icons/Close";
import MetaAccountSelect from "./MetaAccountSelect";
import NotificationPopup from "../../../../common/NotificationPopup";
import CreateMarkupButton from "../../markupSetting/CreateMarkupButton";
import {useUser} from "../../../../Auth";
import {omit} from "lodash";
import {AccountCampaign} from "../CreateTemplateView";
import {ReportList} from "./MetaReportList";
import MetaCampaignSelect from "./MetaCampaignSelect";

const FieldMap = {
    // 添加Meta Ads的字段對應
    customer: "帳戶",
    campaign: "行銷活動",
    adset: "廣告組合",
    ad: "廣告",
    age: "年齡",
    gender: "性別",
    impression_device: "裝置",
    publisher_platform: "平台",
    region: "區域",
    country: "國家/地區",
    hourly_stats_aggregated_by_audience_time_zone: "時段",
    total: "總覽",
    daily: "每日",
};

const formatField = (field) => {
    if (field in FieldMap) {
        return FieldMap[field];
    }
    for (const category in metricsFieldsMap) {
        const found = metricsFieldsMap[category].find(item => item.field === field);
        if (found) {
            return found.label;
        }
    }
    return field;
};


const metricsFieldsMap = {
    基本: [
        {field: "reach", label: "觸及人數"},
        {field: "impressions", label: "曝光次數"},
        {field: "spend", label: "花費金額"}
    ],
    觸及: [
        {field: "spend/reach", label: "千次觸及成本"},
        {field: "reach/impressions", label: "觸及率"}
    ],
    曝光: [
        {field: "spend/impressions", label: "CPM"},
        {field: "frequency", label: "頻率"}
    ],
    互動: [
        {field: "actions.page_engagement", label: "粉絲專頁互動"},
        {field: "actions.like", label: "追蹤或按讚"},
        {field: "actions.comment", label: "留言數"},
        {field: "actions.post_engagement", label: "互動次數"},
        {field: "actions.post_reaction", label: "心情數"},
        {field: "spend/actions.page_engagement", label: "每次粉絲專頁互動成本"},
        {field: "spend/actions.like", label: "每次追蹤或按讚成本"},
        {field: "spend/actions.post_engagement", label: "每次貼文互動成本"},
        {field: "actions.post_engagement/clicks", label: "貼文互動次數/全部點擊"},
        {field: "actions.post_engagement/inline_link_clicks", label: "貼文互動次數/連結點擊"},
    ],
    訊息: [
        {field: "actions.onsite_conversion.messaging_conversation_started_7d", label: "訊息對話開始次數"},
        {field: "spend/actions.onsite_conversion.messaging_conversation_started_7d", label: "每次展開訊息對話成本"},
        {field: "actions.onsite_conversion.messaging_conversation_started_7d/clicks", label: "訊息對話開始次數/全部點擊"},
        {field: "actions.onsite_conversion.messaging_conversation_started_7d/inline_link_clicks", label: "訊息對話開始次數/連結點擊"},
    ],
    影音內容: [
        {field: "video_continuous_2_sec_watched_actions.video_view", label: "影片連續播放 2 秒以上的次數"},
        {field: "actions.video_view", label: "影片播放 3 秒以上的次數"},
        {field: "video_thruplay_watched_actions.video_view", label: "ThruPlay 次數"},
        {field: "video_p25_watched_actions.video_view", label: "影片播放到 25% 的次數"},
        {field: "video_p50_watched_actions.video_view", label: "影片播放到 50% 的次數"},
        {field: "video_p75_watched_actions.video_view", label: "影片播放到 75% 的次數"},
        {field: "video_p95_watched_actions.video_view", label: "影片播放到 95% 的次數"},
        {field: "video_p100_watched_actions.video_view", label: "影片播放到 100% 的次數"},
        {field: "spend/video_continuous_2_sec_watched_actions.video_view", label: "每次影片連續播放 2 秒以上的成本"},
        {field: "spend/actions.video_view", label: "每次影片播放 3 秒以上的成本"},
        {field: "spend/video_thruplay_watched_actions.video_view", label: "每次 ThruPlay 成本"}
    ],
    點擊次數: [
        {field: "clicks", label: "點擊次數（全部）"},
        {field: "spend/clicks", label: "CPC（全部）"},
        {field: "clicks/impressions", label: "CTR（全部）"},
        {field: "actions.link_click", label: "連結點擊次數"},
        {field: "actions.link_click/impressions", label: "CTR（連結點閱率）"},
        {field: "spend/actions.link_click", label: "CPC（單次連結點擊成本）"},
        {field: "actions.landing_page_view", label: "連結頁面瀏覽次數"},
        {field: "spend/actions.landing_page_view", label: "每次連結頁面瀏覽成本"},
    ],
    知名度: [
        {field: "estimated_ad_recallers", label: "廣告回想提升估計值"},
        {field: "estimated_ad_recall_rate", label: "廣告回想提升率估計值"},
        {field: "spend/estimated_ad_recallers", label: "每次廣告回想提升估計值成本"}
    ],
    內容瀏覽: [
        {field: "actions.view_content", label: "內容瀏覽次數"},
        {field: "spend/actions.view_content", label: "每次內容瀏覽的成本"}
    ],
    註冊: [
        {field: "actions.omni_complete_registration", label: "完成註冊次數"},
        {field: "spend/actions.omni_complete_registration", label: "每次完成註冊的成本"},
        {field: "actions.omni_complete_registration/clicks", label: "註冊/全部點擊"},
        {field: "actions.omni_complete_registration/inline_link_clicks", label: "註冊/連結點擊"}
    ],
    潛在顧客: [
        {field: "actions.lead", label: "潛在顧客"},
        {field: "spend/actions.lead", label: "每位潛在顧客取得成本"},
        {field: "actions.lead/clicks", label: "潛在顧客/全部點擊"},
        {field: "actions.lead/link_click", label: "潛在顧客/連結點擊"}
    ],
    提交申請: [
        {field: "actions.submit_application_total", label: "申請表提交次數"},
        {field: "spend/actions.submit_application_total", label: "每次提交申請的成本"},
        {field: "actions.submit_application_total/clicks", label: "提交申請/全部點擊"},
        {field: "actions.submit_application_total/inline_link_clicks", label: "提交申請/連結點擊"}
    ],
    訂閱: [
        {field: "actions.subscribe_total", label: "訂閱"},
        {field: "spend/actions.subscribe_total", label: "每次訂閱成本"},
        {field: "actions.subscribe_total/clicks", label: "訂閱/全部點擊"},
        {field: "actions.subscribe_total/inline_link_clicks", label: "訂閱/連結點擊"}
    ],
    購買: [
        {field: "actions.omni_purchase", label: "購買"},
        {field: "spend/actions.omni_purchase", label: "每次購買的成本"},
        {field: "actions.omni_purchase/clicks", label: "購買/全部點擊"},
        {field: "actions.omni_purchase/inline_link_clicks", label: "購買/連結點擊"},
        {field: "conversion_values.omni_purchase", label: "購買轉換值"},
        {field: "conversion_values.omni_purchase/spend", label: "購買 ROAS"},
        {field: "actions.omni_add_to_cart", label: "加到購物車次數"},
        {field: "spend/actions.omni_add_to_cart", label: "每次加到購物車的成本"}
    ],
    CPAS: [
        {field: "catalog_segment_actions.omni_purchase", label: "CPAS 購買次數"},
        {field: "spend/catalog_segment_actions.omni_purchase", label: "CPAS 轉換成本"},
        {field: "catalog_segment_value.omni_purchase", label: "CPAS 購買轉換值"},
        {field: "catalog_segment_value.omni_purchase/spend", label: "CPAS ROAS"},
        {field: "catalog_segment_actions.omni_add_to_cart", label: "CPAS 加到購物車次數"},
        {field: "spend/catalog_segment_actions.omni_add_to_cart", label: "CPAS 每次加到購物車的成本"},
        {field: "catalog_segment_actions.view_content", label: "CPAS 內容瀏覽次數"}
    ],
    應用程式: [
        {field: "actions.app_use", label: "啟用應用程式"},
        {field: "spend/actions.app_use", label: "每次應用程式啟用成本"},
        {field: "actions.app_install", label: "應用程式安裝"},
        {field: "spend/actions.app_install", label: "每次應用程式安裝成本"}
    ]
};

const MyToggleButton = styled(ToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: '#C81F4D'
    }
});

function MyDraggableGrid({items, setItems}) {

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const newItems = Array.from(items);
        const [removed] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, removed);

        setItems(newItems);
    };
    const handleDelete = (itemToDelete) => {
        setItems((items) => items.filter((item) => item !== itemToDelete));
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
                {(provided) => (
                    <Grid container spacing={1} {...provided.droppableProps} ref={provided.innerRef}>
                        {items.map((item, index) => (
                            <Draggable key={item} draggableId={item} index={index}>
                                {(provided) => (
                                    <Grid item ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <Chip
                                            label={item.split('-').map((item)=>formatField(item)).join('-')}
                                            onDelete={() => handleDelete(item)}
                                            deleteIcon={<CloseIcon />}
                                            variant="outlined"
                                        />
                                    </Grid>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </Grid>
                )}
            </Droppable>
        </DragDropContext>
    );
}

export default ({template}: any) => {
    const { data:accounts, loading, error } = useGetAdsAccountsQuery();

    const [open, setOpen] = useState(false);
    const [createTemplate] = useCreateMetaAdsTemplateMutation();
    const [updateTemplate] = useUpdateMetaAdsTemplateMutation();
    const [name, setName] = useState<string>(template?.name);

    const [reportNames, setReportNames] = useState<string[]>(template?.reportNames ?? []);
    const [metricsFields, setMetricsFields] = useState<string[]>(template?.metricsFields ?? []);
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

    const [selectedAccountCampaignList, setSelectedAccountCampaignList] = useState<AccountCampaign[]>(template?.accountCampaigns ?? []);
    const user = useUser();

    const [visibleCategories, setVisibleCategories] = useState<string[]>([]);
    const toggleCategoryVisibility = (category: string) => {
        setVisibleCategories(prevState =>
            prevState.includes(category)
                ? prevState.filter(cat => cat !== category)
                : [...prevState, category]
        );
    };

    const onSelectAccount = (account: MetaAdsAccount) => {
        const isExisting = selectedAccountCampaignList.some(item => item.accountId === account.id);
        if (!isExisting) {
            setSelectedAccountCampaignList(prevList => [...prevList, { accountId: account.id, accountName: account.name }]);
        }
    }
    const onSelectCampaign = (campaign: { accountId: string, id: string, name: string }) => {
        const isExisting = selectedAccountCampaignList.some(item => item.campaignId === campaign.id);
        if (!isExisting) {
            setSelectedAccountCampaignList(prevList => [...prevList, { accountId: campaign.accountId, accountName: '', campaignId: campaign.id, campaignName: campaign.name }]);
        }
    }

    const onCreate = async () => {
        if (!name) {
            NotificationPopup.error(`請輸入報表名稱`);
        }
        else if (selectedAccountCampaignList.length==0) {
            NotificationPopup.error(`請選擇廣告帳戶或廣告活動`);
        }
        else if (reportNames.length==0) {
            NotificationPopup.error(`請選擇報表類型`);
        }
        else if (metricsFields.length==0) {
            NotificationPopup.error(`請選擇數據類型`);
        }
        else {
            const dto = {
                name: name,
                reportNames: reportNames,
                metricsFields: metricsFields,
                accountCampaigns: selectedAccountCampaignList,
                assigneeId: user?.id,
            };
            if (template == null) {
                await createTemplate({
                    variables: {
                        metaAdsTemplateInput: dto,
                    },
                    refetchQueries: ["getMetaTemplates"],
                });
            }
            else {
                await updateTemplate({
                    variables: {
                        id: template.id,
                        metaAdsTemplateInput: omit(dto,['assigneeId']),
                    },
                    refetchQueries: ["getMetaTemplates"],
                });
            }
            setOpen(false);
        }
    }
    const handleDeleteItem = (indexToDelete: number) => {
        setSelectedAccountCampaignList(prevList =>
            prevList.filter((_, index) => index !== indexToDelete)
        );
    };

    return (
        <div>
            <Dialog maxWidth={"lg"} open={open} fullWidth={true}>
                <DialogTitle style={{ backgroundColor: "#DDD" }}>
                    <Typography variant="h4" align="center">
                        建立Meta報表模板
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} direction='column'>
                        <Grid item>
                            <TextField
                                fullWidth={true}
                                label={"報表名稱"}
                                value={name}
                                onChange={({target})=>{
                                    setName(target.value);
                                }}
                                variant="outlined"/>
                        </Grid>
                        <Grid item container spacing={2}>
                            <Grid item>
                                <MetaAccountSelect onSelectAccount={onSelectAccount}/>
                            </Grid>
                            <Grid item>
                                <MetaCampaignSelect onSelectCampaign={onSelectCampaign}/>
                            </Grid>
                            <Grid item>
                                <CreateMarkupButton isMeta={true}/>
                            </Grid>
                        </Grid>
                        <Grid item container spacing={1} alignItems="center">
                            {selectedAccountCampaignList.map((item, index) => (
                                <Grid item key={index}>
                                    <Card>
                                        <Box margin={1} display="flex" alignItems="center" justifyContent="space-between">
                                            <span>{item.accountId}-{item.accountName} {item.campaignId ? `/${item.campaignId}-${item.campaignName}` : ''}</span>
                                            <IconButton onClick={() => handleDeleteItem(index)} size="small">
                                                <CloseIcon />
                                            </IconButton>
                                        </Box>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>

                        <Grid item>
                            報表類型 (可複選)
                        </Grid>
                        <Grid item>
                            <Card>
                                <Box minHeight={40}>
                                    <MyDraggableGrid items={reportNames} setItems={setReportNames}/>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item container spacing={1}>
                            {
                                ReportList.map((report) => {
                                    return <Grid item key={report.name}>
                                        <Card>
                                            <Box margin={1}>
                                                <div>{report.title}</div>
                                                {
                                                    report.fields.map((field) => {
                                                        const fieldName = `${report.name}-${field}`;
                                                        return <div key={fieldName}>
                                                            <MyToggleButton
                                                                selected={reportNames.includes(fieldName)}
                                                                value="check"
                                                                style={{
                                                                    width: 100,
                                                                }}
                                                                onChange={() => {
                                                                    setReportNames((currentReportNames) => {
                                                                        const index = currentReportNames.indexOf(fieldName);
                                                                        if (index === -1) {
                                                                            return [...currentReportNames, fieldName];
                                                                        } else {
                                                                            return currentReportNames.filter((name) => name !== fieldName);
                                                                        }
                                                                    });
                                                                }}
                                                            >
                                                                {field.split('-').map(key=>formatField(key)).join('-')}
                                                            </MyToggleButton>
                                                        </div>
                                                    })
                                                }
                                            </Box>
                                        </Card>
                                    </Grid>
                                })
                            }
                        </Grid>
                        <Grid item>
                            數據類型 (可複選)
                        </Grid>
                        <Grid container spacing={1}>
                            {Object.keys(metricsFieldsMap).map(category => (
                                <Grid item key={category}>
                                    <MyToggleButton
                                        value="check"
                                        selected={visibleCategories.includes(category)}
                                        onChange={() => toggleCategoryVisibility(category)}
                                    >
                                        {category}
                                    </MyToggleButton>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid item>
                            <Card>
                                <Box minHeight={40}>
                                    <MyDraggableGrid items={metricsFields} setItems={setMetricsFields} />
                                </Box>
                            </Card>
                        </Grid>

                        <Grid item container spacing={1}>
                            {visibleCategories.map(category => (
                                <Grid item>

                                    <Card>
                                        <Box margin={1}>
                                            <div>
                                                {category}
                                            </div>
                                            {
                                                metricsFieldsMap[category].map(({field, label})=>{
                                                    return <div><MyToggleButton
                                                        selected={metricsFields.includes(field)}
                                                        value="check"
                                                        style={{
                                                            width: 200,
                                                        }}
                                                        onChange={()=>{
                                                            const fieldName = field;
                                                            setMetricsFields((currentReportNames) => {
                                                                // 檢查 item.name 是否已存在於 reportNames 中
                                                                const index = currentReportNames.indexOf(fieldName);
                                                                if (index === -1) {
                                                                    // 如果不存在，則加入到 reportNames 陣列
                                                                    return [...currentReportNames, fieldName];
                                                                } else {
                                                                    // 如果存在，則創建一個新的陣列並移除那個值
                                                                    return currentReportNames.filter((name) => name !== fieldName);
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        {label}
                                                    </MyToggleButton></div>
                                                })
                                            }
                                        </Box>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant={"contained"}
                        color={"secondary"}
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        取消
                    </Button>
                    <Button
                        variant={"contained"}
                        color={"secondary"}
                        onClick={async () => {
                            await onCreate();
                        }}
                    >
                        儲存
                    </Button>
                </DialogActions>
            </Dialog>

            <Button
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
            >
                {template ? '編輯' : '新增Meta Ads報表設定'}
            </Button>
        </div>
    )
}
